<template>
  <div
    class="P-error-container page-background-dark d-flex align-center justify-center flex-column"
  >
    <h3 class="primary--text">Error 404</h3>
    <p class="secondary--text">PAGE NOT FOUND</p>
  </div>
</template>
<script>
export default {
  name: "Error404"
};
</script>
<style scoped lang="scss">
.P-error-container {
  position: relative;
  width: 100%;
  height: 100vh;
  h3 {
    font-size: 46px;
    font-weight: 600;
  }
  p {
    font-size: 24px;
  }
}
</style>
